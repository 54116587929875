@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'pkg/typed/typed.scss';
@import 'variables.scss';
@import "@ng-select/ng-select/themes/default.theme.css";

// @import "../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";

html,
body {
  height: 100%;
  display: flex;
}

body {
  margin: 0;
  font-family: 'Inter', "Helvetica Neue", sans-serif;
}

@layer base {

  h1,
  h2,
  h3,
  h5,
  h6 {
    font-weight: 500 !important;
    color: var(--fontColorPrimary);
  }

  h1,
  h2,
  h3,
  h5,
  h6 {
    font-family: Inter, "Helvetica Neue", "Arial", sans-serif !important;
    font-weight: 500;
    color: $fontColor;
  }

  p {
    font-weight: 400;
    color: var(--fontColorPrimary);
  }
}

.add-on-dialog,
.upgrade-dialog {
  .mat-dialog-container {
    overflow: visible;
  }
}

@layer components {
  .input {
    background-color: var(--secondaryColor);
    border: 1px solid rgba(229, 231, 235);
    border-radius: 4px;
    padding: theme('spacing.2');
    font-size: 0.8rem;
    color: var(--fontColorPrimary);
    width: 100%;

    &:focus,
    &:focus-visible {
      outline: none;
      border: 2px solid var(--primaryColor);
    }
  }

  .btn {
    padding: theme('spacing.2');
    font-size: 0.8rem;
    border-radius: 4px;

    &[disabled] {
      filter: grayscale(1);
    }
  }

  .btn-primary {
    background-color: $buttonColor;
    color: var(--buttonFontColorPrimary);

    mat-icon {
      width: 1rem;
      height: 1rem;
      font-size: 1rem;
    }
  }

  .btn-secondary {
    color: var(--fontColorPrimary);
    font-weight: 500;

    mat-icon {
      width: 0.9rem;
      height: 0.9rem;
      font-size: 0.9rem;
    }
  }

  .banner {
    display: flex;
    align-items: center;
    justify-content: start;
    background-color: $lowOpacityBackground;
  }
}

.material-symbols-outlined {
  font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48
}

.mat-divider {
  border-color: $borderColor !important;
}

.menu-toolbar {
  width: 250px;
  border-right: 1px solid $borderColor !important;
  overflow: visible;

  .menu-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0.5rem 0.6rem;

    mat-icon {
      font-size: 0.9rem;
      width: 0.9rem;
      height: 0.9rem;
      display: flex;
      align-items: center;
    }

    h6 {
      font-size: 0.85rem;
      margin-bottom: 0;
    }
  }

  .menu {
    display: flex;
    flex-flow: column;
    padding: 0;

    .menu-item {
      padding: 0.5rem 0.6rem;
      font-size: 0.8rem;
      margin-bottom: 0.25rem;
      text-decoration: none;
      font-weight: 500;
      color: $fontColor;
      cursor: pointer;

      &:hover {
        background-color: $lowOpacityBackground;
        cursor: pointer;
      }

      &.active {
        border-radius: 0;
        background-color: $lowOpacityBackground;
      }

      h6 {
        font-size: inherit;
        margin-bottom: 0;
      }
    }
  }
}

.no-select {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.mat-tooltip {
  font-size: 0.8rem;
  color: #FFFFFF;
  background-color: #232323;
  padding: 3px;
}

.sidenav-secondary {
  position: relative;
  background-color: $white;
  width: 15vw !important;
  border-right-color: $borderColor;
  box-shadow: none;
  overflow: visible !important;
  visibility: visible !important;
  display: flex !important;

  .mat-drawer-inner-container {
    position: unset !important;
    overflow: visible;
  }

  .sidenav-toggle {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;

    right: -1.75rem;
    bottom: 2vh;

    padding: 0px 2px;
    background-color: $white;
    color: $fontColor;
    cursor: pointer;

    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;

    border: 1px solid $borderColor;

    mat-icon {
      color: inherit;
    }
  }
}

.mat-button,
.mat-icon-button {
  background: none;
}

.mat-button-focus-overlay {
  background-color: transparent !important;
}

.mat-button.mat-primary {
  background-color: $white !important;

  span {
    color: $secondaryColor;
    font-size: 0.85rem;
  }
}

input,
input[readonly] {
  border-radius: 4px;
  border: 1px solid $borderColor;
  background-color: transparent;
  font-weight: 500;
  font-size: 0.7rem;
  color: $fontColor;
  appearance: auto;
  -webkit-appearance: auto;
  box-shadow: none;
  resize: none;
  margin-bottom: 0.5rem;
  padding: 5px 10px;
}

.input-group-text {
  border: 1px solid $borderColor;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

/*Change text in autofill textbox*/
input:-webkit-autofill {
  -webkit-text-fill-color: $fontColor;
}

input:placeholder,
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $fontColor !important;
  opacity: 0.5;
}

::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $fontColor !important;
  opacity: 0.5 !important;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $fontColor !important;
  opacity: 0.5;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $fontColor !important;
  opacity: 0.5;
}

.message-sm {
  font-size: 0.85rem;
}

.mat-dialog-container {
  background-color: $secondaryColor;
  padding: 0 !important;
}

.mat-menu-panel {
  background-color: $secondaryColor;
}

.mat-drawer-container {
  background-color: $white !important;
}

.mdc-label {
  -webkit-user-select: none;
  user-select: none;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  height: inherit;
  cursor: pointer;
}

.mat-mdc-tab {
  letter-spacing: 0 !important;
}

.typewriter {
  animation: typew;
  @include typed("Application", "Database", "API",
    1,
    (caret-width: 4px, caret-space: 2px),
  );
}

.welcome-wrapper {
  flex: 3;
  position: relative;
  overflow: hidden;
  background: #061320;
  background: linear-gradient(0deg, #2C016B 0%, #082451 150%);
  display: flex;
  flex-flow: column;
  padding: 3.5rem 3.5em !important;

  .title {
    font-weight: 500;
    font-size: 1.15rem;
    color: $secondaryColor;
    margin-bottom: 0.5rem;
  }

  .text-light {
    color: $secondaryColor !important;
  }

  .message {
    line-height: 1.5;
    font-weight: 300;
    font-size: 1rem;
    color: $secondaryColor;
  }

  .message-lg {
    line-height: 1.5;
    font-weight: 300;
    font-size: 0.95rem;
    color: $secondaryColor;
  }

  .bullet {
    min-width: 15px;
    min-height: 15px;
    width: 15px;
    height: 15px;
    border-radius: 2px;
    background-color: #62D0C5;
    margin-top: 6px;
  }

  .section-bullets {
    flex: 1;
  }

  .section-banner {
    flex: 2;
  }
}

.logo-mini {
  width: 50px;
}

.welcome-wrapper>.logo-floating {
  position: absolute;
  opacity: 0.15;
  top: -10vh;
  right: -10vh;
}

.login-card {
  z-index: 2;
  min-width: 75%;

  .separator {
    height: 3px;
    width: 25px;
    background-color: $buttonColor;
    border-radius: 10px;
  }

  .separator-sm {
    height: 3px;
    width: 15px;
  }
}

.login-card>.login-logo {
  height: auto;
  max-height: 40px !important;
  margin-bottom: 15px;
  pointer-events: none;
}

.login-logo>img {
  height: auto;
  max-height: inherit;
}

.login-wrapper {
  padding: 25px;
  background-color: $secondaryColor !important;
  flex: 2;
}

.input-group-text {
  background-color: #f9fafa;
}

label {
  font-size: 0.8rem;
}

.form-control {
  font-size: 0.85rem;
}

.flex-1 {
  flex: 1;
}

.banner {
  display: flex;

  mat-icon {
    width: 30px;
    height: 30px;
    font-size: 30px;
  }

  p {
    line-height: 1.5;
    font-size: 0.9rem;
  }
}

table {
  border: 1px solid $borderColor !important;
  border-radius: 0.25rem !important;
  border-collapse: separate !important;

  th {
    font-size: 0.85rem;
    border-bottom: 2px solid $primaryColor;
    padding: 10px 20px;
  }
}

.mat-row.mat-no-data-row {
  font-size: 0.85rem !important;
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: $buttonColor;
}

.ng-value-label,
.ng-option-label {
  font-size: 0.85rem;
  color: $fontColor;
}

.ng-option-marked {
  .ng-option-label {
    font-size: 0.85rem;
    font-weight: 500;
  }
}

.ng-option-disabled {
  .ng-option-label {
    font-size: 0.85rem;
    color: inherit;
  }
}

.text-primary {
  color: $buttonColor;
}

.text-danger {
  color: $buttonDangerColor;
}

mat-tab-header {
  border-bottom: 1px solid $borderColor;
}

.border-bottom {
  border-bottom: 1px solid $borderColor;
}

@media (max-width: 768px) {
  .welcome-wrapper {
    display: none !important;
    visibility: hidden;
  }

  .login-wrapper {
    padding: 25px;

  }
}

@media (max-width: 576px) {
  .login-card {
    max-width: 100%;
    width: 100%;
    min-height: 100%;
    border-radius: 0 !important;
    background-position: -100% 80%;
    background-size: 80%;
  }
}

@keyframes typing {
  from {
    width: 0
  }

  to {
    width: 100%
  }
}

@keyframes blink-caret {

  from,
  to {
    border-color: transparent
  }

  50% {
    border-color: #ffffff;
  }
}