@use '@angular/material' as mat;


@include mat.core();
$app-accent-colors: (
    50: #fffce6,
    100: #fff8b7,
    200: #fff27a,
    300: #fff065,
    400: #ffec42,
    500: #fde71d,
    600: #ffe925,
    700: #ffe923,
    800: #ffe70e,
    900: #E71343,
    A100: #ffffff,
    A200: #fffcfb,
    A400: #ffd2c8,
    A700: #ffbeae,
    contrast: (50: #000000, 100: #000000, 200: #000000, 300: #000000, 400: #000000, 500: #000000, 600: #000000, 700: #000000, 800: #000000, 900: #ffffff, A100: #000000, A200: #000000, A400: #000000, A700: #000000, )
);
$app-primary-colors: (
    50: #e4e4e5,
    100: #bcbcbf,
    200: #eff2fc,
    300: #626469,
    400: #404249,
    500: #1e2129,
    600: #1a1d24,
    700: #16181f,
    800: #121419,
    900: #0a0b0f,
    A100: #005db9,
    A200: #082451,
    A400: #005db9,
    A700: #0047d4,
    contrast: (50: #000000, 100: #000000, 200: #000000, 300: #ffffff, 400: #ffffff, 500: #ffffff, 600: #ffffff, 700: #ffffff, 800: #ffffff, 900: #ffffff, A100: #000000, A200: #ffffff, A400: #ffffff, A700: #ffffff, )
);

$app-theme-primary: mat.define-palette($app-primary-colors, A400);
$app-theme-accent: mat.define-palette($app-primary-colors, 400);
$app-theme-warn: mat.define-palette($app-accent-colors, 800);
$app-theme: mat.define-light-theme((
    color: (
        primary: $app-theme-primary,
        accent: $app-theme-accent,
        warn: $app-theme-warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

@include mat.core-theme($app-theme);
@include mat.all-component-themes($app-theme);

mat-form-field.mat-form-field {
    font-size: 16px;
}