// Import material theming functions


@function hex($hexcolor, $opacity) {
    $red: red($hexcolor);
    $green: green($hexcolor);
    $blue: blue($hexcolor);
    $alpha: alpha($hexcolor);

    @if $opacity ==1 or $opacity ==100% {
        @return unquote("rgba(#{$red},#{$green},#{$blue},#{$alpha})");
    }

    @else {
        @return unquote("rgba(#{$red},#{$green},#{$blue},#{$opacity})");
    }
}

$primaryColor: #253240;

:root {
    --lowOpacityBackground: #D9E8F7;
    --lowOpacitySuccess: #dcfff9;
    --lowOpacityError: #ffe9ee;
    --lowOpacityWarning: #fff8e8;
    --activeColor:#ffffff;
    --borderColor:#dfdfdf;
    --primaryColor: #061320;
    --primaryColorDark: #021025;
    --navItemActiveColor: #031430;
    --secondaryColor: #FFFFFF;
    --buttonColor: #074889;
    --buttonTertiary: #E71343;
    --buttonTertiaryFontColor:#E71343;
    --buttonSecondary: #E8E8E8;
    --buttonHoverColor: #1BAFDE;
    --buttonFocusColor: #1BAFDE;
    --fontColor: #111111;
    --secondaryFontColor: #E8E8E8;
    --secondaryFontColorLight: #E8E8E8;
    --secondaryFontColorDark: #c3c3c3;
    --formControlBgColor: #FFFFFF;
    --formControlBorderColor: #E8E8E8;
    --white: #ffffff;
    --bgLight: #FAFAFA;
    --bgGrey: #E8E8E8;
    --bgPrimary: #1BAFDE;
    --bgSuccess: #006655;
    --bgError: #E71343;
    --bgWarning: #ffae00;
    --deviceBorderColor: #2a2d32;
    --bgColor: var(--white);
    --buttonPrimaryFontColor:#ffffff;
    --buttonDangerFontColor:#ffffff;
    --buttonDangerColor:#E71343;
    --buttonSecondaryFontColor:#ffffff;
    --buttonFontColor:#ffffff;
    --activeClassColor:#ffffff;
    --fontColorPrimary: #1C1C1C;
    --fontColorSecondary: var(--secondaryColor);
    --buttonColorPrimary: var(--buttonColor);
    --buttonColorSecondary: var(--secondaryColor);
    --buttonColorDestructive: #E71343;
    --buttonFontColorPrimary: var(--secondaryColor);
    --buttonFontColorSecondary: var(--primaryColor);
    --buttonFontColorDestructive: var(--secondaryColor);
    --iconSuccessColor: rgb(25, 135, 84)
}

$primaryColor: var(--primaryColor);
$primaryColorDark: var(--primaryColorDark);
$navItemActiveColor: var(--navItemActiveColor);
$secondaryColor: var(--secondaryColor);
$secondaryFontColor:var(--secondaryFontColor);
$buttonColor: var(--buttonColor);
$buttonTertiary: var(--buttonTertiary);
$buttonSecondary: var(--buttonSecondary);
$buttonHoverColor: var(--buttonHoverColor);
$buttonFocusColor: var(--buttonFocusColor);
$fontColor: var(--fontColor);
$borderColor:var(--borderColor);
$secondaryFontColorLight: var(--secondaryFontColorLight);
$secondaryFontColorDark: var(--secondaryFontColorDark);
$formControlBgColor: var(--formControlBgColor);
$formControlBorderColor: var(--formControlBorderColor);
$white: var(--white);
$bgLight: var(--bgLight);
$bgGrey: var(--bgGrey);
$bgPrimary: var(--bgPrimary);
$bgSuccess: var(--bgSuccess);
$bgError: var(--bgError);
$bgWarning: var(--bgWarning);
$deviceBorderColor: var(--deviceBorderColor);
$activeColor:var(--activeColor);
$buttonTertiaryFontColor:var(--buttonTertiaryFontColor);
$buttonPrimaryFontColor:var(--buttonPrimaryFontColor);
$buttonDangerFontColor:var(--buttonDangerFontColor);
$buttonDangerColor:var(--buttonDangerColor);
$buttonSecondaryFontColor:var(--buttonSecondaryFontColor);
$buttonFontColor:var(--buttonFontColor);
$lowOpacityBackground:var(--lowOpacityBackground);
$lowOpacitySuccess:var(--lowOpacitySuccess);
$lowOpacityError: var(--lowOpacityError);
$lowOpacityWarning: var(--lowOpacityWarning);
$activeClassColor:var(--activeClassColor);
:export {
    primaryColor: var(--primaryColor);
    secondaryColor: var(--secondaryColor);
}